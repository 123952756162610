.section {
  padding-top: 5rem;
  display: flex;
  flex-direction: column-reverse;

  @media #{$mobile} {
    padding-top: 10rem;
  }

  @media #{$tablet} {
    flex-direction: row;
    padding-top: 16rem;
  }

  &--reversed {
    flex-direction: column;

    @media #{$tablet} {
      flex-direction: row;
    }
  }

  &__image {
    width: 100%;

    @media #{$mobile} {
      display: flex;
      justify-content: center;
    }

    @media #{$tablet} {
      display: block;
    }

    & > img {
      margin: 0 var(--offset);
      // height: auto;
      width: calc(100% - (var(--offset) * 2));
      display: block;
      object-fit: contain;

      @media #{$mMobile} {
        margin: 0;
        max-width: 100%;
        height: auto;
        width: 100%;
        object-fit: unset;
      }

      @media #{$tablet} {
        width: unset;
      }

      @media #{$sDesktop} {
        max-width: unset;
      }

      &.contact-img {
        display: none;

        @media #{$tablet} {
          display: block;
          max-width: unset;
          width: calc(100% - var(--offset));
        }

        @media #{$mDesktop} {
          width: unset;
        }
      }
    }

    &--left {
      & > img {
        @media #{$tablet} {
          margin-left: var(--offset);
        }
      }
    }

    &--right {
      & > img {
        @media #{$tablet} {
          margin-right: var(--offset);
        }
      }
    }
  }

  &__content {
    width: 100%;
    margin-bottom: 5rem;

    @media #{$tablet} {
      margin-bottom: 0;
    }

    &--left {
      @media #{$tablet} {
        padding-left: 4rem;
      }
    }

    &--right {
      @media #{$tablet} {
        padding-right: 12rem;
      }
    }
  }

  &__heading {
    margin-bottom: 5rem;
    line-height: 1;
    font-size: 4rem;
    color: $darkGray;

    @media #{$mobile} {
      font-size: 6rem;
    }
  }

  &__text {
    font-size: 1.8rem;

    @media #{$mobile} {
      font-size: 2.1rem;
    }

    & + .section__text,
    & + .section__list {
      margin-top: 1.5rem;
    }
  }

  &__list {
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    list-style-position: inside;

    @media #{$mobile} {
      font-size: 2.1rem;
    }

    & > li + li {
      margin-top: 1rem;
    }
  }
}

.signature {
  margin-top: 3rem;
  font-size: 1.6rem;
  font-style: italic;
  line-height: 1.2;

  @media #{$mobile} {
    margin-top: 5rem;
    font-size: 1.8rem;
    line-height: 1.5;
    text-align: right;
  }

  &__part {
    width: 20rem;
    display: block;

    @media #{$mobile} {
      width: auto;
    }

    &:first-of-type {
      margin-bottom: 2rem;

      @media #{$mobile} {
        margin-bottom: 0;
      }
    }
  }
}

.bold {
  font-weight: 700;
}

.btn-container {
  margin: 5rem 0;
  display: flex;
  justify-content: center;

  @media #{$mobile} {
    margin: 8rem 0 5rem;
    justify-content: flex-end;
  }

  button {
    padding: 2rem 5rem;
    width: 100%;
    max-height: 8rem;
    font-style: italic;
    font-size: 1.8rem;
    font-family: inherit;
    color: $btnText;
    border: 1px solid $btnText;
    transition: background-color 150ms, color 150ms;

    @media #{$mobile} {
      padding: 3rem 7rem;
      width: unset;
      font-size: 2.1rem;
    }

    &:hover {
      @media #{$mobile} {
        color: #fff;
        background-color: $btnText;
      }
    }

    span {
      display: inline-block;

      @media #{$mobile} {
        transform: translateY(-3px);
      }
    }
  }
}

.hidden-content {
  margin: 1.5rem 0;
  transition: max-height 300ms;
  max-height: 0;
  overflow: hidden;
}

.map-container {
  position: relative;
  width: 100%;
}

.map {
  height: 67rem;
  width: calc(100% - 2 * (var(--offset)));
  margin: 0 var(--offset);

  display: block;

  @media #{$tablet} {
    margin: 0 0 0 var(--offset);
    width: calc(100% - var(--offset));
  }

  @media #{$mDesktop} {
    margin: 0 0 0 var(--offset);
    max-width: 80.1rem;
  }

  &__desc {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: var(--offset);
    padding: 3.4rem 3.6rem;
    background-color: $gray;

    span {
      font-size: 2.1rem;
      font-weight: 600;
      color: $bgColor;
    }
  }
}

.contact-part {
  padding: 3rem 0;
  max-width: 45rem;
  border-bottom: 1px solid $darkGray;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    max-width: unset;
    border: none;
  }

  &__text {
    font-size: 1.8rem;

    &--grid {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 0.5rem;

      @media #{$mobile} {
        grid-template-columns: 1fr 4fr;
      }

      & > span {
        &.margin-bottom {
          margin-bottom: 2rem;
        }
      }
    }

    &--narrow {
      display: block;
      width: 20rem;

      @media #{$mobile} {
        width: unset;
      }
    }
  }

  &__icons {
    margin-top: 2rem;

    img {
      display: inline-block;

      & + img {
        margin-left: 2rem;
      }
    }
  }
}
