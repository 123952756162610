$desktop: "only screen and (min-width: 1700px)";
$mDesktop: "only screen and (min-width: 1520px)";
$sDesktop: "only screen and (min-width: 1365px)";
$tablet: "only screen and (min-width: 1281px)";
$sTablet: "only screen and (min-width: 1025px)";
$mobile: "only screen and (min-width: 769px)";
$mMobile: "only screen and (min-width: 479px)";
$sMobile: "only screen and (min-width: 371px)";
$menuBreak: "only screen and (min-width: 1080px)";
$belowMenuBreak: "only screen and (max-width: 1080px)";
$onlyMobile: "only screen and (max-width: 768px)";

$gray: #787774;
$darkGray: #4d4c4a;
$langActive: #41403d;
$menuLinks: #868581;
$bgColor: #f7f6f2;
$btnText: #85847d;

:root {
  --offset: -3rem;

  @media #{$sTablet} {
    --offset: -5vw;
  }

  @media #{$mDesktop} {
    --offset: -10vw;
  }
}
