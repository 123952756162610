*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: adobe-garamond-pro, serif;
  font-size: 1.6rem;
  line-height: 1.3;
  max-width: 100vw;
  overflow-x: hidden;
  color: $gray;
  background-color: $bgColor;
}

button {
  background: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
}

a,
a:visited {
  text-decoration: none;
  color: $gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
}

ol {
  list-style-position: inside;
}

.main-wrapper {
  padding: 0 calc(var(--offset) * -1);
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
