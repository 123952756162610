.header {
  padding-top: 7rem;

  @media #{$mobile} {
    padding-top: 5rem;
  }

  &__langs {
    display: flex;
    list-style-type: none;
    width: fit-content;
    margin-left: auto;
  }

  &__lang {
    position: relative;
    padding: 2rem;

    &:last-child {
      padding-right: 0;
    }

    & + .header__lang {
      &::before {
        content: "|";
        position: absolute;
        left: -2px;
        top: 50%;
        transform: translateY(calc(-50% - 2px));
      }
    }

    & > a {
      transition: color 150ms;

      &:hover {
        color: $darkGray;
      }
    }

    &--active {
      a {
        color: $langActive;
      }
    }
  }

  &__nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media #{$tablet} {
      padding-bottom: 0;
      position: relative;
      flex-direction: row;
    }
  }

  &__nav {
    position: absolute;
    left: 0;
    padding-top: 8rem;
    background-color: $bgColor;
    width: 100%;
    transform: translateX(-100%);
    transition: transform 300ms;

    @media #{$tablet} {
      padding-top: 0;
      left: unset;
      right: 0;
      width: unset;
      background-color: unset;
      transform: none;
    }

    &.is-active {
      transform: translateX(0);
    }
  }

  &__hamburger {
    position: absolute;
    top: 1rem;
    right: 1rem;

    @media #{$tablet} {
      display: none;
    }
  }

  &__hero-img {
    padding-top: 5rem;

    @media #{$sTablet} {
      padding-top: 7rem;
    }

    img {
      margin: 0 var(--offset);
      max-width: calc(100% - 2 * var(--offset));
      min-height: 35rem;
      display: block;
      object-fit: cover;

      @media #{$sTablet} {
        height: auto;
        margin: 0;
        max-width: 100%;
      }
    }
  }
}

.menu {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media #{$tablet} {
    flex-direction: row;
    align-items: unset;
  }

  &__item {
    padding: 2.5rem;

    &:last-child {
      @media #{$tablet} {
        padding-right: 0;
      }
    }

    & > a {
      font-size: 1.8rem;
      color: $menuLinks;
      transition: color 150ms;

      &:hover {
        color: $darkGray;
      }
    }
  }
}

.logo {
  display: block;
  margin-top: 2rem;

  @media #{$mMobile} {
    margin-top: 0;
  }
}
