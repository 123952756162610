.footer {
  min-height: 24rem;
  background-color: $darkGray;
  margin: 0 calc(var(--offset));
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    padding: 0 calc(var(--offset) * -1);
    width: 100%;
    max-width: 149rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$mDesktop} {
      padding: 0;
      flex-direction: row;
    }
  }

  &__text {
    &:last-child {
      margin-top: 2rem;

      @media #{$mDesktop} {
        margin-top: 0;
      }
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
